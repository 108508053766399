import { graphql } from "react-relay";
import type { Route } from "../core";
import type Session from "./Session";
import { routesSessionQueryResponse } from "./__generated__/routesSessionQuery.graphql";

export default [
  {
    path: ["/s/:id"],
    query: graphql`
      query routesSessionQuery($id: ID!) {
        session: node(id: $id) {
          ...Session_session
          ... on Event {
            type
            name
            class {
              title
            }
          }
        }

        attendees(eventId: $id, first: 10) {
          ...Session_attendees
        }
      }
    `,
    variables: ({ params }) => ({ id: btoa(`Event:${params?.id}`) }),
    component: () => import(/* webpackChunkName: "session" */ "./Session"),
    response: (data) =>
      data.session?.class &&
      data.session?.type === "Session" &&
      data.attendees && {
        title: `${data.session.name || data.session.class.title} · ThatClass`,
        props: data,
        layout: false,
      },
  } as Route<typeof Session, routesSessionQueryResponse>,
];
