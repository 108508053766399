import React from "react";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import { createOperationDescriptor, getRequest } from "relay-runtime";
import { NotificationsCountQuery } from "./__generated__/NotificationsCountQuery.graphql";

type NotificationsCount = { unreadCount?: number };

// GraphQL query for fetching unread notifications count
const vars = {};
const query = graphql`
  query NotificationsCountQuery {
    notifications(first: 0) {
      unreadCount
    }
  }
`;

// How often the notifications count needs to be updated (in ms)
const INTERVAL = 20 * 1000;

let lastFetch = Date.now() - INTERVAL;
const operation = createOperationDescriptor(getRequest(query), vars);

/**
 * Returns the number of unread posts, comments, likes, etc.
 * and starts listening for updates.
 */
export function useNotificationsCount(): NotificationsCount {
  const relay = useRelayEnvironment();
  const snap = relay.lookup(operation.fragment);
  const [state, setState] = React.useState<NotificationsCount>({
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    unreadCount: (snap.data as any)?.notifications?.unreadCount,
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      // Ensure that refetch is not fired more often than
      // {INTERVAL} ms when the browser tap is active, and
      // no more often than {INTERVAL * 5} ms when the
      // browser tab is hidden.
      if (
        lastFetch > Date.now() - INTERVAL + 100 ||
        (document.hidden && lastFetch > Date.now() - INTERVAL * 3)
      ) {
        return;
      }

      // Attempt to fetch notifications count from the API
      fetchQuery<NotificationsCountQuery>(relay, query, vars, {
        fetchPolicy: "network-only",
      }).toPromise();

      lastFetch = Date.now();
    }, 3000);

    const store = relay.subscribe(snap, (nextSnap) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const unreadCount = (nextSnap.data as any)?.notifications?.unreadCount;
      setState((prevState) =>
        prevState.unreadCount === unreadCount ? prevState : { unreadCount },
      );
    });

    return function dispose() {
      clearInterval(interval);
      store.dispose();
    };
  }, [relay]);

  return state;
}
