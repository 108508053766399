/**
 * @generated SignedSource<<c757ae41d612be3c2a33d9e00ab5ac75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventType = "Session" | "Meetup" | "Zoom" | "%future added value";
export type routesSessionQuery$variables = {
  id: string;
};
export type routesSessionQueryVariables = routesSessionQuery$variables;
export type routesSessionQuery$data = {
  readonly session: {
    readonly type?: EventType;
    readonly name?: string | null;
    readonly class?: {
      readonly title: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"Session_session">;
  } | null;
  readonly attendees: {
    readonly " $fragmentSpreads": FragmentRefs<"Session_attendees">;
  } | null;
};
export type routesSessionQueryResponse = routesSessionQuery$data;
export type routesSessionQuery = {
  variables: routesSessionQueryVariables;
  response: routesSessionQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "id"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routesSessionQuery",
    "selections": [
      {
        "alias": "session",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Session_session"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Class",
                "kind": "LinkedField",
                "name": "class",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "AttendeeConnection",
        "kind": "LinkedField",
        "name": "attendees",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Session_attendees"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routesSessionQuery",
    "selections": [
      {
        "alias": "session",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledFor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Class",
                "kind": "LinkedField",
                "name": "class",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Picture",
                    "kind": "LinkedField",
                    "name": "cover",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "type": "Event",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "AttendeeConnection",
        "kind": "LinkedField",
        "name": "attendees",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AttendeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": "attendee",
                "args": null,
                "concreteType": "Attendee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Picture",
                        "kind": "LinkedField",
                        "name": "picture",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "86057dddc969d1a8ed7ebb157a355d1d",
    "id": null,
    "metadata": {},
    "name": "routesSessionQuery",
    "operationKind": "query",
    "text": "query routesSessionQuery(\n  $id: ID!\n) {\n  session: node(id: $id) {\n    __typename\n    ...Session_session\n    ... on Event {\n      type\n      name\n      class {\n        title\n        id\n      }\n    }\n    id\n  }\n  attendees(eventId: $id, first: 10) {\n    ...Session_attendees\n  }\n}\n\nfragment Session_attendees on AttendeeConnection {\n  edges {\n    attendee: node {\n      id\n      completed\n      user {\n        username\n        firstName\n        lastName\n        picture {\n          url\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment Session_session on Event {\n  id\n  name\n  scheduledFor\n  class {\n    id\n    title\n    cover {\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0895ae448780d6b774cfa3780c31e43";

export default node;
