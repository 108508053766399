/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import {
  Environment,
  GraphQLResponseWithData,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";

type Config = {
  baseUrl?: string;
  request?: Request;
  records?: ConstructorParameters<typeof RecordSource>[0];
};

/* eslint-disable @typescript-eslint/no-explicit-any */

export function createRelay(config: Config = {}): Environment {
  const recordSource = new RecordSource(config.records);
  const store = new Store(recordSource);
  const baseUrl = config.baseUrl || "";

  const network = Network.create((operation, variables): Promise<any> => {
    const cookie = config.request?.headers.get("cookie");
    return fetch(`${baseUrl}/api`, {
      method: "POST",
      headers: {
        ...(cookie && { cookie }),
        "content-Type": "application/json",
      },
      body: JSON.stringify({ query: operation.text, variables }),
      ...(!config.request && { credentials: "include" }),
    }).then((res) =>
      res.json().then(
        (res) => {
          (res as GraphQLResponseWithData).errors?.map((err) => {
            if (err.extensions?.input) {
              err.errors = err.extensions.input;
            }
          });
          return res;
        },
        (err) => {
          console.error(err);
          return Promise.reject("Failed to fetch data from the API.");
        },
      ),
    );
  });

  return new Environment({
    store,
    network,
    handlerProvider: null,
  });
}

export function toRawId<T extends string | null | undefined>(globalId: T): T {
  return globalId && (atob(globalId).split(":")[1] as T);
}
