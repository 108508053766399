/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import * as React from "react";
import {
  Environment,
  fetchQuery,
  graphql,
  useFragment,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import {
  createOperationDescriptor,
  getFragment,
  getRequest,
  getSingularSelector,
  Snapshot,
} from "relay-runtime";
import type { AuthQuery } from "./__generated__/AuthQuery.graphql";
import { AuthSignOutMutation } from "./__generated__/AuthSignOutMutation.graphql";
import { Auth_me$key } from "./__generated__/Auth_me.graphql";
import {
  Auth_user$data,
  Auth_user$key,
} from "./__generated__/Auth_user.graphql";

const query = graphql`
  query AuthQuery {
    ...Auth_me
  }
`;

const meFragment = graphql`
  fragment Auth_me on Root {
    me {
      ...Auth_user
    }
  }
`;

const userFragment = graphql`
  fragment Auth_user on User {
    id
    username
    email
    emailVerified
    firstName
    lastName
    admin
    picture {
      url
    }
    rank
    rankProgress
  }
`;

const signOutMutation = graphql`
  mutation AuthSignOutMutation {
    signOut
  }
`;

const variables = {};
const operation = createOperationDescriptor(getRequest(query), variables);

export type User = Auth_user$data | null;

/**
 * Returns the currently logged in user object (`me`), `null` for anonymous
 * users, and `undefined` when the user status has not been resolved yet.
 */
export function useCurrentUser(forceFetch = false): User | null {
  const relay = useRelayEnvironment();

  // Attempt to read the current user record (me) from the local store.
  const [snap, setSnap] = React.useState<Snapshot>(() =>
    relay.lookup(operation.fragment),
  );

  // Subscribe to updates
  React.useEffect(() => {
    const subscription = relay.subscribe(snap, (x) => setSnap(x));
    return () => subscription.dispose();
  }, [relay]);

  // Once the component is mounted, attempt to load user record from the API.
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      fetchQuery<AuthQuery>(relay, query, variables, {
        networkCacheConfig: { force: forceFetch },
        fetchPolicy: "store-or-network",
      }).toPromise();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [relay, forceFetch]);

  const root = useFragment(meFragment, snap.data as Auth_me$key | null);
  const me = useFragment(userFragment, root?.me as Auth_user$key | null);

  return me;
}

export function useAuth(): () => Promise<User | undefined> {
  const relay = useRelayEnvironment();
  return React.useCallback(
    () =>
      fetchQuery<AuthQuery>(relay, query, variables, {
        networkCacheConfig: { force: true },
        fetchPolicy: "network-only",
      })
        .toPromise()
        .then(() => getCurrentUser(relay)),
    [],
  );
}

export function useSignOut(): () => void {
  const [commit] = useMutation<AuthSignOutMutation>(signOutMutation);
  return React.useCallback(
    function () {
      commit({
        variables: {},
        onCompleted(_, errors) {
          if (errors) throw errors[0];
          window.location.href = "/";
        },
      });
    },
    [commit],
  );
}

export function getCurrentUser(relay: Environment): User | null | undefined {
  let snap = relay.lookup(operation.fragment);

  if (snap.isMissingData) return undefined;
  if (!snap.data) return null;

  let selector = getSingularSelector(getFragment(meFragment), snap.data);
  snap = relay.lookup(selector);

  if (snap.isMissingData) return undefined;
  if (!snap.data?.me) return null;

  selector = getSingularSelector(getFragment(userFragment), snap.data.me);
  snap = relay.lookup(selector);

  if (snap.isMissingData) return undefined;
  return snap.data as User | null;
}
