import { graphql } from "react-relay";
import type { Route } from "../core";
import type Members from "./Members";
import type Team from "./Team";
import { routesTeamDetailsQueryResponse } from "./__generated__/routesTeamDetailsQuery.graphql";
import { routesTeamMembersQueryResponse } from "./__generated__/routesTeamMembersQuery.graphql";

export default [
  {
    path: "/t/:slug",
    query: graphql`
      query routesTeamDetailsQuery(
        $auth: Boolean!
        $slug: String!
        $status: MembershipStatus
      ) {
        ...Auth_me @include(if: $auth)
        team(slug: $slug) {
          ...TeamDetailsCard_team
          ...SessionsCard_team
          ... on Team {
            slug
            name
            membership {
              id
              status
              admin
            }
          }
        }
        members(team: $slug, status: $status) {
          ...TeamDetailsCard_members
        }
        ...PostList_posts @arguments(team: $slug)
        ...SessionsCard_data @arguments(team: $slug)
      }
    `,
    variables: (ctx) => ({ ...ctx.params, status: "Approved" }),
    component: () => import(/* webpackChunkName: "team" */ "./Team"),
    response(data) {
      return data.team
        ? {
            title: `${data.team.name} · ThatClass`,
            props: data,
          }
        : undefined;
    },
  } as Route<typeof Team, routesTeamDetailsQueryResponse>,
  {
    path: "/t/:slug/members",
    query: graphql`
      query routesTeamMembersQuery(
        $auth: Boolean!
        $slug: String!
        $status: MembershipStatus
      ) {
        ...Auth_me @include(if: $auth)
        team(slug: $slug) {
          slug
          name
          membership {
            id
            status
            admin
          }
        }
        members(team: $slug, status: $status) {
          ...Members_members
        }
      }
    `,
    variables: (ctx) => ({ ...ctx.params, status: null }),
    component: () => import(/* webpackChunkName: "team" */ "./Members"),
    response(data) {
      return data.team && data.members
        ? {
            title: `${data.team.name} Members · ThatClass`,
            props: data,
          }
        : undefined;
    },
  } as Route<typeof Members, routesTeamMembersQueryResponse>,
];
