import type { Route } from "../../core";
import type Dashboard from "./Dashboard";

export default [
  {
    path: "/admin",
    component: () => import(/* webpackChunkName: "legal" */ "./Dashboard"),
    response: () => ({
      title: "Dashboard · ThatClass",
    }),
  } as Route<typeof Dashboard>,
];
