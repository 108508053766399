/**
 * @generated SignedSource<<e8a19748e249f6206f59f784fcb4512b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type routesClassesCreateQuery$variables = {};
export type routesClassesCreateQueryVariables = routesClassesCreateQuery$variables;
export type routesClassesCreateQuery$data = {
  readonly classTypes: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  } | null>;
  readonly equipment: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  } | null>;
  readonly instructors: ReadonlyArray<{
    readonly id: string;
    readonly displayName: string | null;
    readonly user: {
      readonly id: string;
      readonly username: string | null;
      readonly picture: {
        readonly url: string | null;
      };
    };
  }>;
};
export type routesClassesCreateQueryResponse = routesClassesCreateQuery$data;
export type routesClassesCreateQuery = {
  variables: routesClassesCreateQueryVariables;
  response: routesClassesCreateQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ClassType",
    "kind": "LinkedField",
    "name": "classTypes",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Equipment",
    "kind": "LinkedField",
    "name": "equipment",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Instructor",
    "kind": "LinkedField",
    "name": "instructors",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Picture",
            "kind": "LinkedField",
            "name": "picture",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routesClassesCreateQuery",
    "selections": (v2/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routesClassesCreateQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "52809c0cb3702c8dbca6c681699cbf95",
    "id": null,
    "metadata": {},
    "name": "routesClassesCreateQuery",
    "operationKind": "query",
    "text": "query routesClassesCreateQuery {\n  classTypes {\n    id\n    name\n  }\n  equipment {\n    id\n    name\n  }\n  instructors {\n    id\n    displayName\n    user {\n      id\n      username\n      picture {\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "97dac346459dc15d0ecef8c2c0788670";

export default node;
