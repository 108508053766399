import type { ThemeOptions } from "@mui/material/styles";
import history from "history/browser";
import * as React from "react";

/**
 * Style overrides for Material UI components.
 */
export const components: ThemeOptions["components"] = {
  MuiLink: {
    defaultProps: {
      underline: "none",
      onClick(event: React.MouseEvent<HTMLAnchorElement>) {
        const { target } = event.currentTarget;
        if (
          !event.defaultPrevented && // onClick prevented default
          event.button === 0 && // ignore everything but left clicks
          (!target || target === "_self") && // let browser handle "target=_blank" etc.
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault();
          history.push(event.currentTarget.href);
        }
      },
    },
  },

  MuiFormControl: {
    defaultProps: {
      variant: "outlined",
    },
  },

  MuiInputLabel: {
    defaultProps: {
      shrink: true,
    },
    styleOverrides: {
      root: {
        // transform: "none",
        // position: "relative",
        // paddingBottom: "6px",
        // fontWeight: 600,
      },
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
      input: {
        // "&:focus": { boxShadow: "none" },
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 1000px rgb(22,23,24) inset",
        },
      },
    },
  },

  MuiTextField: {
    defaultProps: {
      InputLabelProps: { shrink: true },
    },
  },

  // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/Button
  MuiButton: {
    defaultProps: {
      variant: "contained",
    },
    styleOverrides: {
      text: {
        textTransform: "uppercase",
      },
      contained: {
        boxShadow: "none",
        background: "linear-gradient(146.25deg, #C03DAE 3.5%, #00BFF7 112.5%)",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedSecondary: {
        boxShadow: "none",
        background: "white",
        "&:hover": {
          boxShadow: "none",
        },
        color: "#C03DAE",
      },
      sizeLarge: {
        padding: "14px 28px",
      },
    },
  },
  // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/ButtonGroup
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  },

  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: "1.125rem",
      },
    },
  },
};

function isModifiedEvent(event: React.MouseEvent) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}
