import { createTheme } from "@mui/material/styles";
import { components } from "./components";

/**
 * Global ThatClass styles based on Material UI default theme.
 *
 * @see https://next.material-ui.com/customization/default-theme/
 */
export const theme = createTheme(
  {
    palette: {
      mode: "dark",
      primary: {
        main: "rgb(45,136,255)",
      },
      background: {
        default: "rgb(24,25,26)",
      },
    },

    typography: {
      fontFamily: [
        "Poppins",
        `-apple-system`,
        `"BlinkMacSystemFont"`,
        `"Segoe UI"`,
        `"Roboto"`,
        `"Oxygen"`,
        `"Ubuntu"`,
        `"Cantarell"`,
        `"Fira Sans"`,
        `"Droid Sans"`,
        `"Helvetica Neue"`,
        `sans-serif`,
      ].join(","),
    },

    components,
  },
  {
    typography: {
      h1: { fontSize: "2em", fontWeight: 600 },
      h2: { fontSize: "1.5em", fontWeight: 600 },
      h3: { fontSize: "1.3em", fontWeight: 600 },
      h4: { fontSize: "1em", fontWeight: 600 },
      h5: { fontSize: "0.8em", fontWeight: 600 },
      h6: { fontSize: "0.7em", fontWeight: 600 },
      button: { textTransform: "none" },
    },
  },
);
