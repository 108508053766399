import { graphql } from "react-relay";
import type { Route } from "../core";
import type ComingSoon from "./ComingSoon";
import type Home from "./Home";
import { routesHomeComingQueryResponse } from "./__generated__/routesHomeComingQuery.graphql";
import { routesHomeQueryResponse } from "./__generated__/routesHomeQuery.graphql";

export default [
  {
    path: "/",
    query: graphql`
      query routesHomeQuery($auth: Boolean!) {
        ...Auth_me @include(if: $auth)
      }
    `,
    component: () => import(/* webpackChunkName: "home" */ "./Home"),
    response: () => ({
      title: "ThatClass",
      description: "",
    }),
  } as Route<typeof Home, routesHomeQueryResponse>,
  {
    path: "/ComingSoon",
    query: graphql`
      query routesHomeComingQuery($auth: Boolean!) {
        ...Auth_me @include(if: $auth)
      }
    `,
    component: () => import(/* webpackChunkName: "home" */ "./ComingSoon"),
    response: () => ({
      title: "ThatClass | Coming Soon",
      description: "",
      layout: false,
    }),
  } as Route<typeof ComingSoon, routesHomeComingQueryResponse>,
];
