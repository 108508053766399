/**
 * @generated SignedSource<<a308344448e88ead8902209d10a981a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationsCountQuery$variables = {};
export type NotificationsCountQueryVariables = NotificationsCountQuery$variables;
export type NotificationsCountQuery$data = {
  readonly notifications: {
    readonly unreadCount: number;
  } | null;
};
export type NotificationsCountQueryResponse = NotificationsCountQuery$data;
export type NotificationsCountQuery = {
  variables: NotificationsCountQueryVariables;
  response: NotificationsCountQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 0
      }
    ],
    "concreteType": "NotificationConnection",
    "kind": "LinkedField",
    "name": "notifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unreadCount",
        "storageKey": null
      }
    ],
    "storageKey": "notifications(first:0)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsCountQuery",
    "selections": (v0/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsCountQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "304d88fad4fdb4c595d8bbde85881a78",
    "id": null,
    "metadata": {},
    "name": "NotificationsCountQuery",
    "operationKind": "query",
    "text": "query NotificationsCountQuery {\n  notifications(first: 0) {\n    unreadCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "0708c155f6cade809a7d271748abc3e1";

export default node;
