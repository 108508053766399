import { Notifications } from "@mui/icons-material";
import { Badge, Box, IconButton, Link } from "@mui/material";
import React, { Suspense } from "react";
import { RingAvatar } from ".";
import { useCurrentUser, useNotificationsCount } from "../core";
import { UserMenu } from "../menus";
import { NextClass } from "./NextClass";

export function AccountMenu(): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const notifications = useNotificationsCount();
  const me = useCurrentUser();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "left" }}
      >
        <Box fontWeight="fontWeightBold">{me?.firstName}</Box>
        <Suspense fallback={<div></div>}>
          <NextClass />
        </Suspense>
      </Box>
      <Link href={`/notifications`} color="default">
        <IconButton sx={{ marginLeft: 2 }}>
          <Badge badgeContent={notifications.unreadCount} color="error">
            <Notifications />
          </Badge>
        </IconButton>
      </Link>

      <IconButton
        sx={{ m: 1 }}
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <RingAvatar
          picture={me?.picture?.url || undefined}
          progress={me?.rankProgress || undefined}
          pathColor={me?.rank || undefined}
          letter={me?.username || undefined}
        />
      </IconButton>
      <UserMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        keepMounted
      />
    </Box>
  );
}
