import { graphql } from "react-relay";
import type { Route } from "../core";
import type Classes from "./Classes";
import type Explore from "./Explore";
import { routesExplorerClassesQueryResponse } from "./__generated__/routesExplorerClassesQuery.graphql";
import { routesExplorerQueryResponse } from "./__generated__/routesExplorerQuery.graphql";

export default [
  {
    path: ["/explore", "/explore/c/:id"],
    query: graphql`
      query routesExplorerQuery {
        classes(first: 10) @connection(key: "Explore_classes") {
          ...Explore_classes
          edges {
            node {
              id
            }
          }
        }
        teams(first: 4) {
          edges {
            team: node {
              ...TeamCard_team
              id
              name
              description
            }
          }
        }
      }
    `,
    component: () => import(/* webpackChunkName: "explore" */ "./Explore"),
    response: (data, ctx) => ({
      title: "Explorer · ThatClass",
      props: {
        classId: ctx.params?.id,
        classesRef: data.classes,
        teams: data.teams,
      },
    }),
  } as Route<typeof Explore, routesExplorerQueryResponse>,
  {
    path: ["/classes", "/classes/:id"],
    query: graphql`
      query routesExplorerClassesQuery(
        $instructor: String
        $equipment: String
        $type: String
        $first: Int
      ) {
        classes(
          instructor: $instructor
          equipment: $equipment
          type: $type
          first: $first
        ) @connection(key: "Classes_classes") {
          ...Classes_classes
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    variables: (ctx) => ({
      instructor: ctx.query.get("instructor"),
      equipment: ctx.query.get("equipment"),
      type: ctx.query.get("type"),
      first: ctx.query.get("first") || 48,
    }),
    component: () => import(/* webpackChunkName: "classes" */ "./Classes"),
    response: (data, ctx) => ({
      title: "Classes · ThatClass",
      props: {
        classId: ctx.params?.id,
        classesRef: data.classes,
      },
    }),
  } as Route<typeof Classes, routesExplorerClassesQueryResponse>,
];
