import { AccountCircle, Settings } from "@mui/icons-material";
import {
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import * as React from "react";
import { useCurrentUser, useNavigate, useSignOut } from "../core";
import { Logout } from "../icons";

type UserMenuProps = Omit<
  MenuProps,
  "id" | "role" | "open" | "anchorOrigin" | "transformOrigin"
>;

export function UserMenu(props: UserMenuProps): JSX.Element {
  const { PaperProps, MenuListProps, ...other } = props;

  const navigate = useNavigate();
  const signOut = useSignOut();
  const me = useCurrentUser();

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    props.onClose?.(event, "backdropClick");
    navigate(event);
  }

  return (
    <Menu
      id="user-menu"
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{ ...PaperProps, sx: { ...PaperProps?.sx, width: 320 } }}
      MenuListProps={{ ...MenuListProps, dense: true }}
      {...other}
    >
      <MenuItem
        component={Link}
        href={`/@${me?.username}`}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ minWidth: 40 }} children={<AccountCircle />} />
        <ListItemText primary="Profile" />
      </MenuItem>

      <MenuItem component={Link} href="/settings" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }} children={<Settings />} />
        <ListItemText primary="Account Settings" />
      </MenuItem>

      <MenuItem onClick={signOut}>
        <ListItemIcon sx={{ minWidth: 40 }} children={<Logout />} />
        <ListItemText primary="Log Out" />
      </MenuItem>
    </Menu>
  );
}
