import { graphql } from "react-relay";
import type { Route } from "../core";
import type TeamCreate from "./TeamCreate";
import type Teams from "./Teams";

export default [
  {
    path: "/social",
    query: graphql`
      query routesSocialQuery($auth: Boolean!) {
        ...Auth_me @include(if: $auth)
        teams {
          edges {
            team: node {
              ...TeamCard_team
              id
              name
              description
              membership {
                id
                status
                admin
              }
            }
          }
        }
      }
    `,
    component: () => import(/* webpackChunkName: "teams" */ "./Teams"),
    response: (props) => ({
      title: "Social · ThatClass",
      props,
    }),
  } as Route<typeof Teams>,
  {
    path: "/social/team/create",
    component: () => import(/* webpackChunkName: "teams" */ "./TeamCreate"),
    response: () => ({
      title: "Create Team · ThatClass",
    }),
  } as Route<typeof TeamCreate>,
];
