import {
  DirectionsBike as DirectionsBikeIcon,
  Home as HomeIcon,
  People as PeopleIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  IconButton,
  Link,
  Toolbar,
} from "@mui/material";
import React from "react";
import { useCurrentUser } from "../core";
import { AccountMenu } from "./AccountMenu";

const navigation = [
  { href: "/", name: "Home" },
  { href: "/explore", name: "Explore" },
  { href: "/social", name: "Social" },
];

const linkStyles = {
  color: "text.primary",
  m: 2,
  fontSize: 16,
  "&:hover": {
    color: "primary.main",
    borderBottom: 2,
  },
};

export function AppToolbar(): JSX.Element {
  const me = useCurrentUser();

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          flexGrow: 1,
          background: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar component={Container}>
          <Box sx={{ flexGrow: 2 }}>
            <Hidden mdDown>
              <Link href="/" color="inherit">
                <Box
                  sx={{ flexGrow: 3 }}
                  component="img"
                  src="/thatclass-logo.png"
                />
              </Link>
            </Hidden>
            <Hidden mdUp>
              <Link href="/" color="inherit">
                <Box
                  sx={{ flexGrow: 3 }}
                  component="img"
                  src="/thatclass_mobile-logo.png"
                />
              </Link>
            </Hidden>
          </Box>
          <Hidden mdDown>
            <Box
              sx={{ flexGrow: 2 }}
              children={navigation.map((x) => (
                <Link sx={linkStyles} key={x.name} href={x.href}>
                  {x.name}
                </Link>
              ))}
            ></Box>
          </Hidden>
          {me && <AccountMenu />}
          {me === null && (
            <Button
              variant="outlined"
              color="inherit"
              sx={{ marginRight: 2 }}
              href="/login"
              children="Login"
            />
          )}
          {me === null && (
            <Button color="inherit" href="/register">
              Create Account
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: "auto", bottom: 0 }}
        >
          <Toolbar sx={{ justifyContent: "center" }}>
            <IconButton sx={{ flexGrow: 1 }} color="inherit" href="/">
              <HomeIcon />
            </IconButton>
            <IconButton sx={{ flexGrow: 1 }} color="inherit" href="/explore">
              <DirectionsBikeIcon />
            </IconButton>
            <IconButton sx={{ flexGrow: 1 }} color="inherit" href="/social">
              <PeopleIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
    </div>
  );
}
