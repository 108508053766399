/**
 * @generated SignedSource<<e00ccfb89655551c9be2a8d02d0bb5aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NextClassQuery$variables = {};
export type NextClassQueryVariables = NextClassQuery$variables;
export type NextClassQuery$data = {
  readonly events: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly scheduledFor: string | null;
      } | null;
    } | null> | null;
  } | null;
};
export type NextClassQueryResponse = NextClassQuery$data;
export type NextClassQuery = {
  variables: NextClassQueryVariables;
  response: NextClassQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "user",
        "value": "$me"
      }
    ],
    "concreteType": "EventConnection",
    "kind": "LinkedField",
    "name": "events",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EventEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledFor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "events(user:\"$me\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NextClassQuery",
    "selections": (v0/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NextClassQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0adb3553a0469413446d224d64d856f2",
    "id": null,
    "metadata": {},
    "name": "NextClassQuery",
    "operationKind": "query",
    "text": "query NextClassQuery {\n  events(user: \"$me\") {\n    edges {\n      node {\n        id\n        scheduledFor\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2cd0de2c3d5101af76ea4ae7366a1ca5";

export default node;
