import { graphql } from "react-relay";
import type { Route } from "../core";
import type Settings from "./Settings";
import { routesSettingsQueryResponse } from "./__generated__/routesSettingsQuery.graphql";

export default [
  {
    path: "/settings",
    query: graphql`
      query routesSettingsQuery($auth: Boolean!) {
        ...Auth_me @include(if: $auth)
        me {
          ...Settings_me
          ...DeleteAccountDialog_user
        }
      }
    `,
    authorize: true,
    component: () => import(/* webpackChunkName: "home" */ "./Settings"),
    response: (data) => ({
      title: "Account Settings · ThatClass",
      props: data,
    }),
  } as Route<typeof Settings, routesSettingsQueryResponse>,
];
