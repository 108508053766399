import { graphql } from "react-relay";
import type { Route } from "../../core";
import type ClassCreate from "./ClassCreate";
import type Classes from "./Classes";

export default [
  {
    path: "/admin/classes",
    component: () => import(/* webpackChunkName: "legal" */ "./Classes"),
    response: () => ({
      title: "Dashboard · ThatClass",
    }),
  } as Route<typeof Classes>,
  {
    path: "/admin/classes/new",
    query: graphql`
      query routesClassesCreateQuery {
        classTypes {
          id
          name
        }
        equipment {
          id
          name
        }
        instructors {
          id
          displayName
          user {
            id
            username
            picture {
              url
            }
          }
        }
      }
    `,
    component: () => import(/* webpackChunkName: "admin" */ "./ClassCreate"),
    response: (data) => ({
      title: "Add New Class · ThatClass",
      props: data,
    }),
  } as Route<typeof ClassCreate, routesClassesCreateQuery>,
];
