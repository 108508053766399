import type { Route } from "../core";
import type Login from "./Login";
import type LoginHelp from "./LoginHelp";
import type PhoneVerification from "./PhoneVerification";
import type PhoneVerificationCode from "./PhoneVerificationCode";
import type Register from "./Register";

export default [
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "login" */ "./Login"),
    response: () => ({
      title: "Sign In · ThatClass",
      layout: false,
    }),
  } as Route<typeof Login>,

  {
    path: "/login/help",
    component: () => import(/* webpackChunkName: "login" */ "./LoginHelp"),
    response: () => ({
      title: "Forgot your password? · ThatClass",
      layout: false,
    }),
  } as Route<typeof LoginHelp>,

  {
    path: "/register",
    component: () => import(/* webpackChunkName: "register" */ "./Register"),
    response: () => ({
      title: "Create an Account · ThatClass",
      layout: false,
    }),
  } as Route<typeof Register>,

  {
    path: "/verify",
    component: () =>
      import(/* webpackChunkName: "register" */ "./PhoneVerification"),
    response: () => ({
      title: "Twp Step Verification · ThatClass",
      layout: false,
    }),
  } as Route<typeof PhoneVerification>,

  {
    path: "/verify/auth",
    component: () =>
      import(/* webpackChunkName: "register" */ "./PhoneVerificationCode"),
    response: () => ({
      title: "Twp Step Verification · ThatClass",
      layout: false,
    }),
  } as Route<typeof PhoneVerificationCode>,
];
