/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import { default as adminClasses } from "../admin/routes.classes";
import { default as adminDashboard } from "../admin/routes.dashboard";
import auth from "../routes.auth";
import explore from "../routes.explore";
import home from "../routes.home";
import legal from "../routes.legal";
import notifications from "../routes.notifications";
import profile from "../routes.profile";
import session from "../routes.session";
import settings from "../routes.settings";
import social from "../routes.social";
import team from "../routes.team";

export default [
  ...home,
  ...profile,
  ...auth,
  ...legal,
  ...explore,
  ...social,
  ...settings,
  ...team,
  ...session,
  ...adminClasses,
  ...adminDashboard,
  ...notifications,
];
