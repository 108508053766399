/**
 * @generated SignedSource<<2b1a4f67ceb3335bab2a16029901885d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routesExplorerClassesQuery$variables = {
  instructor?: string | null;
  equipment?: string | null;
  type?: string | null;
  first?: number | null;
};
export type routesExplorerClassesQueryVariables = routesExplorerClassesQuery$variables;
export type routesExplorerClassesQuery$data = {
  readonly classes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
    readonly " $fragmentSpreads": FragmentRefs<"Classes_classes">;
  } | null;
};
export type routesExplorerClassesQueryResponse = routesExplorerClassesQuery$data;
export type routesExplorerClassesQuery = {
  variables: routesExplorerClassesQueryVariables;
  response: routesExplorerClassesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equipment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "instructor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v4 = {
  "kind": "Variable",
  "name": "equipment",
  "variableName": "equipment"
},
v5 = {
  "kind": "Variable",
  "name": "instructor",
  "variableName": "instructor"
},
v6 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Class",
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routesExplorerClassesQuery",
    "selections": [
      {
        "alias": "classes",
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "concreteType": "ClassConnection",
        "kind": "LinkedField",
        "name": "__Classes_classes_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Classes_classes"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClassEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "routesExplorerClassesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "ClassConnection",
        "kind": "LinkedField",
        "name": "classes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClassEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": "class",
                "args": null,
                "concreteType": "Class",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Picture",
                    "kind": "LinkedField",
                    "name": "cover",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Instructor",
                    "kind": "LinkedField",
                    "name": "instructors",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "filters": [
          "instructor",
          "equipment",
          "type"
        ],
        "handle": "connection",
        "key": "Classes_classes",
        "kind": "LinkedHandle",
        "name": "classes"
      }
    ]
  },
  "params": {
    "cacheID": "91684ee9117cae1f2b83c3de3af1e8df",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": null,
          "direction": "forward",
          "path": [
            "classes"
          ]
        }
      ]
    },
    "name": "routesExplorerClassesQuery",
    "operationKind": "query",
    "text": "query routesExplorerClassesQuery(\n  $instructor: String\n  $equipment: String\n  $type: String\n  $first: Int\n) {\n  classes(instructor: $instructor, equipment: $equipment, type: $type, first: $first) {\n    ...Classes_classes\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ClassCard_class on Class {\n  id\n  title\n  cover {\n    url\n  }\n  duration\n  instructors {\n    id\n    displayName\n    user {\n      username\n      id\n    }\n  }\n}\n\nfragment Classes_classes on ClassConnection {\n  edges {\n    class: node {\n      ...ClassCard_class\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e18be5bcad3955d17c38778bcd4f4c7a";

export default node;
