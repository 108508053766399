import { graphql } from "react-relay";
import type { Route } from "../core";
import Notifications from "./Notifications";
import { routesNotificationsQueryResponse } from "./__generated__/routesNotificationsQuery.graphql";

export default [
  {
    path: "/notifications",
    query: graphql`
      query routesNotificationsQuery($auth: Boolean!) {
        ...Auth_me @include(if: $auth)
        notifications(first: 48)
          @connection(key: "Notifications_notifications") {
          ...Notifications_notifications
          edges {
            cursor
          }
        }
      }
    `,
    component: () =>
      import(/* webpackChunkName: "notifications" */ "./Notifications"),
    response(data) {
      return data?.notifications
        ? {
            title: `Notifications · ThatClass`,
            props: data,
          }
        : undefined;
    },
  } as Route<typeof Notifications, routesNotificationsQueryResponse>,
];
