import { graphql } from "react-relay";
import type { Route } from "../core";
import type Profile from "./Profile";
import { routesProfileQueryResponse } from "./__generated__/routesProfileQuery.graphql";

export default [
  {
    path: "/@:username",
    query: graphql`
      query routesProfileQuery($username: String!) {
        user(username: $username) {
          ...Profile_user
          username
          firstName
          lastName
        }
        teams(user: $username, first: 5) {
          ...Profile_teams
        }
        ...PostList_posts @arguments(user: $username)
        ...SessionsCard_data @arguments(user: $username)
      }
    `,
    component: () => import(/* webpackChunkName: "profile" */ "./Profile"),
    response(data) {
      return (
        data.user && {
          title: `${data.user.firstName} ${data.user.lastName} (@${data.user.username}) · ThatClass`,
          props: data,
        }
      );
    },
  } as Route<typeof Profile, routesProfileQueryResponse>,
];
