import { Avatar as MuiAvatar, Box } from "@mui/material";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

type AvatarProps = {
  picture?: string;
  pathColor?: string;
  progress?: number;
  letter?: string;
  size?: string;
};

function firstLetter(letter?: string) {
  return letter?.charAt(0).toUpperCase();
}

export function RingAvatar({
  picture = "",
  progress = 0,
  pathColor = "",
  letter = undefined,
  size = "sm",
}: AvatarProps): JSX.Element {
  const ringSize = size == "sm" ? 48 : 96;
  const avatarSize = size == "sm" ? 36 : 72;

  return (
    <Box
      sx={{
        width: ringSize,
        height: ringSize,
        borderRadius: "50%",
        m: 1,
      }}
    >
      <CircularProgressbarWithChildren
        css={{ width: ringSize + "px", height: ringSize + "px" }}
        strokeWidth={4}
        value={progress * 100}
        styles={buildStyles({
          trailColor: "#414141",
          pathColor: pathColor,
        })}
      >
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizontally centered. */}
        <MuiAvatar
          sx={{
            width: avatarSize,
            height: avatarSize,
            bgcolor: "transparent",
            color: "white",
          }}
          src={picture}
        >
          {firstLetter(letter)}
        </MuiAvatar>
      </CircularProgressbarWithChildren>
    </Box>
  );
}
