import { Box, Link } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { graphql, usePreloadedQuery } from "react-relay";
import { toRawId } from "../core";
import { Streaming } from "../icons/Streaming";
import type { NextClassQuery } from "./__generated__/NextClassQuery.graphql";

export function NextClass() {
  const data = usePreloadedQuery<NextClassQuery>(
    graphql`
      query NextClassQuery {
        events(user: "$me") {
          edges {
            node {
              id
              scheduledFor
            }
          }
        }
      }
    `,
    1,
  );

  const nextEvent =
    data.events?.edges.length > 0 ? data.events?.edges[0].node : null;

  // Live session now
  if (nextEvent != null) {
    const isLiveEvent =
      new Date(nextEvent.scheduledFor) > Date.now() ? "upcoming" : "live";

    if (isLiveEvent === "live") {
      return (
        <Box>
          <Link color="inherit" href={`/s/${toRawId(nextEvent.id)}`}>
            Next Class: <span className="font-bold">Now</span>
            {/* Tailwind CSS animate pulse */}
            <Streaming className="animate-pulse ml-1" />
          </Link>
        </Box>
      );
    } else if (isLiveEvent === "upcoming") {
      return (
        nextEvent !== null && (
          <Box>
            Next Class:{" "}
            <span className="font-bold">
              {nextEvent !== null &&
                format(new Date(nextEvent.scheduledFor), "p")}
            </span>
          </Box>
        )
      );
    }
  }
  return <Box>Next Class: None</Box>;
}
