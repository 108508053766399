/**
 * @generated SignedSource<<80dbbf3c02b16534c9185905a51ed782>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routesSettingsQuery$variables = {
  auth: boolean;
};
export type routesSettingsQueryVariables = routesSettingsQuery$variables;
export type routesSettingsQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"Settings_me" | "DeleteAccountDialog_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"Auth_me">;
};
export type routesSettingsQueryResponse = routesSettingsQuery$data;
export type routesSettingsQuery = {
  variables: routesSettingsQueryVariables;
  response: routesSettingsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "auth"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routesSettingsQuery",
    "selections": [
      {
        "condition": "auth",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Auth_me"
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Settings_me"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeleteAccountDialog_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routesSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailVerified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "admin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Picture",
            "kind": "LinkedField",
            "name": "picture",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rankProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "540fe840f3ae5740f12cd2624e82ca2d",
    "id": null,
    "metadata": {},
    "name": "routesSettingsQuery",
    "operationKind": "query",
    "text": "query routesSettingsQuery(\n  $auth: Boolean!\n) {\n  ...Auth_me @include(if: $auth)\n  me {\n    ...Settings_me\n    ...DeleteAccountDialog_user\n    id\n  }\n}\n\nfragment Auth_me on Root {\n  me {\n    ...Auth_user\n    id\n  }\n}\n\nfragment Auth_user on User {\n  id\n  username\n  email\n  emailVerified\n  firstName\n  lastName\n  admin\n  picture {\n    url\n  }\n  rank\n  rankProgress\n}\n\nfragment DeleteAccountDialog_user on User {\n  id\n  username\n  email\n}\n\nfragment Settings_me on User {\n  ...Auth_user\n  id\n  username\n  email\n  emailVerified\n  firstName\n  lastName\n  bio\n  location\n  picture {\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f4042c9b6458d7e3e181eab653a52a9";

export default node;
